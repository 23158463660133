<template>
  <commonTitle v-bind:datas="datas"></commonTitle>
  <div class="about ">
    <div class="clearfix pab30">
      <div class="w1200 b_fff pab30">
        <!-- <p class="font_28 mgt40">公司简介</p> -->
        <!-- <div class="b_border"></div> -->
        <div class="pad30 clearfix min_border">
          <div class=" font_16 c_333 lh30 info_text">
			<div class="info_center">
			  <p style="text-align: left; font-size: 22px;">
				  再获殊荣！忆能科技荣获“2022年度最佳用户侧储能应用场景创新项目奖”
			  </p>
			  <p style="text-align: left; font-size: 15px; color: #999999;">
				2023-01-21
			  </p>
			</div>
            <img
              src="../../assets/images/news/newsdetail/HEZUO-1.jpg"
              class="info_img_center" style="width: 80%;height: 80%;"
              alt="成都忆能科技有限公司"
            />
            <div class="info_center">
              <p class="min_font">
                &nbsp;&nbsp;&nbsp;&nbsp;8月，深圳虚拟电厂管理中心举行揭牌仪式，这是国内首家虚拟电厂管理中心。该虚拟电厂主要负责虚拟电厂管理平台的建设和日常运行维护，建立虚拟电厂日常运行的管理制度，组织开展虚拟电厂用户注册、资源接入、调试管理、接收和执行调度指令、响应监测、效果评估等工作。 
              </p>
			  <p class="min_font">
			    &nbsp;&nbsp;&nbsp;&nbsp;这次，虚拟电厂管理中心在深圳揭牌，让“虚拟电厂”的概念再次走进大众视野。那么，深圳为何会选择建立国内首个虚拟电厂管理中心呢？ 
				人口增量全省第一
			  </p>
              
              <p class="min_font"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
	import commonTitle from '@/components/common/commonTitle.vue'
	export default {
		components: {
			commonTitle,
		},
		methods: {
			
		},
		data() {
			return {
				datas:["news"],
			};
		},
		mounted() {
			
		},
	};
</script>
<style lang="scss">
@import "@/assets/css/about.scss";


</style>